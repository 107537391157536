import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import SkuttliAPI from "../SkuttliAPI";
// import SkuttliAPI from "./SkuttliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Grid, Typography } from "@mui/material";
import RabbitDto from "../Dtos/RabbitDto";

function RabbitCrudView(props) {
  let history = useNavigate();

  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [fathers, setFathers] = useState([]);
  const [father, setFather] = useState(null);
  const [mothers, setMothers] = useState([]);
  const [mother, setMother] = useState(null);
  // //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let rabbits = await SkuttliAPI.getRabbits();
      setFathers(rabbits);
      setMothers(rabbits);
    };
    load1();
  }, []);
  return (
    <Box p={2}>
      <Box p={2}>
        <Typography variant="h4">Ny Råtta</Typography>
      </Box>

      <Box p={1}>
        <TextField
          id="outlined-basic"
          label="Name"
          style={{ margin: "8px" }}
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <br />
        <TextField
          id="outlined-basic"
          label="Color"
          style={{ margin: "8px" }}
          variant="outlined"
          value={color}
          onChange={(event) => setColor(event.target.value)}
        />
        <br />
        <Autocomplete
          id="mothers"
          sx={{ width: 300, margin: "8px", marginTop: "16px", marginBottom: "24px" }}
          options={mothers}
          value={mother}
          autoHighlight
          onChange={(event, newValue) => {
            console.log(newValue);
            setMother(newValue);
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option.name}</b>
                  </Box>
                  <Typography>
                    <i>
                      {option.color} - {option.id}
                    </i>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Mother"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        
        <Autocomplete
          id="fathers"
          sx={{ width: 300, margin: "8px", marginTop: "24px", marginBottom: "16px" }}
          options={fathers}
          value={father}
          autoHighlight
          onChange={(event, newValue) => {
            console.log(newValue);
            setFather(newValue);
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option.name}</b>
                  </Box>
                  <Typography>
                    <i>
                      {option.color} - {option.id}
                    </i>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Father"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>
      <Box p={2}>
        <Button
          variant="contained"
          onClick={async () => {
            console.log("father");
            console.log(father);
            console.log(mother);
            let fatherIdToSave = undefined;
            let motherIdToSave = undefined;

            if(father){
              fatherIdToSave = father.id;
            }
            if(mother){
              motherIdToSave = mother.id;
            }
            await SkuttliAPI.createRabbit(
              new RabbitDto(SkuttliAPI.generateGuid(), name, color, fatherIdToSave, motherIdToSave, new Date())
            );
            history("/rabbits");
            // let le = await SkuttliAPI.getRabbits();
            // setRabbits(le);
          }}
        >
          Lägg till
        </Button>
      </Box>
      <br />
      <br />
    </Box>
  );
}

export default RabbitCrudView;
