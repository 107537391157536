import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkuttliAPI from "../SkuttliAPI";
// import SkuttliAPI from "./SkuttliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";

function RabbitsView(props) {
  let history = useNavigate();

  const [rabbits, setRabbits] = useState([]);
  const [loading, setLoading] = useState(true);
  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      setLoading(true);
      let le = await SkuttliAPI.getRabbits();
      setRabbits(le);
      setLoading(false);
    };
    load1();
  }, []);
  return (
    <Box p={2}>
      <Box m={1}>
        <Button
          variant="contained"
          onClick={async () => {
            history("/rabbitCrud");
          }}
        >
          Lägg till
        </Button>
      </Box>
      <Card style={{ backgroundColor: "white", margin: "8px" }}>
        {!loading && (
          <List style={{ backgroundColor: "white" }}>
            {rabbits.map((element) => {
              return (
                <ListItem key={element.rabbitId} button>
                  <CardActionArea
                    onClick={() => {
                      //history("/rabbit/" + element.rabbitId);
                      window.location.href = "https://skuttli.com/RabbitOverview.aspx?RabbitId=" + element.rabbitId;
                    }}
                  >
                    <ListItemText>
                      {element.name}
                      {" - "}
                      <i>{element.rabbitId}</i>
                    </ListItemText>
                  </CardActionArea>
                  {/* <Button
                    variant="contained"
                    onClick={async () => {
                      await SkuttliAPI.deleteRabbit(element.id);
                      let le = await SkuttliAPI.getRabbits();
                      setRabbits(le);
                    }}
                  >
                    <DeleteForever></DeleteForever>
                  </Button> */}
                  <br />
                </ListItem>
              );
            })}
          </List>
        )}
        {loading && (
          <Box p={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: "8px" }}
            >
              <CircularProgress></CircularProgress>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="body1">Loading...</Typography>
            </Grid>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default RabbitsView;
