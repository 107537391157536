import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import SkuttliAPI from "../SkuttliAPI";
// import SkuttliAPI from "./SkuttliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate  } from "react-router-dom";
import { Typography } from "@mui/material";

function LoginView(props) {
  let history = useNavigate ();

  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  // //Kör vid uppstart
  // useEffect(() => {
  //   const load1 = async () => {
  //     let le = await SkuttliAPI.getRabbits();
  //     setRabbits(le);
  //   };
  //   load1();
  // }, []);
  return (
    <Box p={2}>
      <Typography variant="h1">Login</Typography>
      
    </Box>
  );
}

export default LoginView;
