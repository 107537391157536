import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SkuttliAPI from "../SkuttliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import ApplicationState from "../ApplicationState";

export default function ClubsView(props) {
  let history = useNavigate();
  const location = useLocation();
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalMembers, setTotalMembers] = useState(0);
  const [user, setUser] = useState(null);
  
  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      setLoading(true);
      let le = await SkuttliAPI.getClubs(1);
      setClubs(le);
      setLoading(false);
      let tot = 0;
      le.forEach(element => {
        tot += element.members;
      });
      setTotalMembers(tot);
      LoadUserDataFromWebFormsApp();
      if(ApplicationState.getUser()){
        setUser(ApplicationState.getUser());
      }
    };
    load1();
  }, []);

  const LoadUserDataFromWebFormsApp = async () => {
    ApplicationState.initializeState();
    if(!ApplicationState.getUser()){
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if(token && token.length > 0){
          let u = await SkuttliAPI.getUserGetByToken(token);
          ApplicationState.setUser(u);
          console.log(u);
        }
    }
  }

  return (
      <Card style={{ backgroundColor: "white", margin: "8px" }}>
        <Box m={2}>
        <Typography variant="h5">Clubs</Typography>
        <Typography variant="h6">Total Members: {totalMembers}</Typography>
        {user && <Typography variant="h6">User: {user.firstName} {user.lastName}</Typography>}
        </Box>
        
        {!loading && (
          <List style={{ backgroundColor: "white" }}>
            {clubs
             .sort((a, b) => {
                return a.club.clubName.localeCompare(b.club.clubName);
              })
             .map((element) => {
              return (
                <ListItem key={element.clubId} button>
                  <CardActionArea
                    onClick={() => {
                      //history("/rabbit/" + element.rabbitId);
                      window.location.href = "https://skuttli.com/ClubPage.aspx?ClubId=" + element.club.clubId;
                    }}
                  >
                    <ListItemText>
                      {element.club.clubName}
                      {" - "}
                      {element.club.clubAbbreviation}
                      {" - "}
                      {element.members}
                      {" Members"}
                    </ListItemText>
                  </CardActionArea>
                  {/* <Button
                    variant="contained"
                    onClick={async () => {
                      await SkuttliAPI.deleteRabbit(element.id);
                      let le = await SkuttliAPI.getRabbits();
                      setRabbits(le);
                    }}
                  >
                    <DeleteForever></DeleteForever>
                  </Button> */}
                  <br />
                </ListItem>
              );
            })}
          </List>
        )}
        {loading && (
          <Box p={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingBottom: "8px" }}
            >
              <CircularProgress></CircularProgress>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="body1">Loading...</Typography>
            </Grid>
          </Box>
        )}
      </Card>
  );
}
