import { v4 as uuidv4 } from "uuid";

class SkuttliAPI {
  static getApiUrl(path) {
    //return `${ApplicationState.getState().config.API_URL}/${path}`;
    return 'https://skuttliapi.azurewebsites.net/' + path;
  }

  static generateGuid() {
    return uuidv4();
  }

  static async getRabbits() {
    let result = await fetch(this.getApiUrl("rabbits"));
    let text = await result.text();
    let json = JSON.parse(text);
    console.log(json);
    return json;
  }

  static async getRabbit(id) {
    let result = await fetch(this.getApiUrl("rabbits/" + id), {
      method: "GET",
    });
    let text = await result.text();
    let json = JSON.parse(text);
    console.log(json);
    return json;
  }

  static async getAncestorsForRabbit(id) {
    let result = await fetch(this.getApiUrl("rabbits/AncestorsForRabbit/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async getOffspringsForRabbit(id) {
    let result = await fetch(this.getApiUrl("rabbits/OffspringsForRabbit/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  static async createRabbit(dto) {
    await this.postDto("rabbits",dto);
  }

  static async deleteRabbit(id) {
    await this.deleteDto("rabbits",id);
  }

  static async postDto(controller, dto){
    const response = await fetch(this.getApiUrl(controller + "/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async putDto(controller, dto) {
    const response = await fetch(this.getApiUrl(controller + "/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async deleteDto(controller, id) {
    let response = await fetch(this.getApiUrl(controller + "/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status == "200") {
      return "Ok";
    } else {
      console.log("Error occured for DELETE");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async getClubs(unionId) {
    let result = await fetch(this.getApiUrl("clubs") + "?unionId=" + unionId);
    let text = await result.text();
    let json = JSON.parse(text);
    console.log(json);
    return json;
  }

  static async getUserGetByToken(token) {
    let result = await fetch(this.getApiUrl("User/GetByToken/" + token));
    let text = await result.text();
    let json = JSON.parse(text);
    console.log(json);
    return json;
  }
}

export default SkuttliAPI;
