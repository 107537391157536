export const StateKey = "State";

export default class ApplicationState {
  static storeState(state) {
    sessionStorage.setItem(StateKey, JSON.stringify(state));
  }

  static hasState() {
    let state = sessionStorage.getItem(StateKey);
    return state !== undefined && state !== null;
  }

  static getState() {
    let value = sessionStorage.getItem(StateKey);
    if (value === undefined || value === null) {
      throw Error("Failed to load state from session storage");
    }
    try {
      var object = JSON.parse(value);
      return object;
    } catch (e) {
      throw Error("Failed to parse stored state: " + e);
    }
  }

  static initializeState() {
    if(!this.hasState()) {
      this.storeState({user: null});
    }
  }

  static getUser() {
    return this.getState().user;
  }

  static setUser(user) {
    let state = this.getState();
    state.user = user;
    this.storeState(state);
  }

  static viewMenuItem() {
    return true;
  }

  static isAdmin() {
    return true;
  }

  static isLoggedIn() {
    return true;
  }

}
