import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkuttliAPI from "../SkuttliAPI";
// import SkuttliAPI from "./SkuttliAPI";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Card, List, ListItem, ListItemText, Paper } from "@mui/material";
import { useParams } from 'react-router-dom';

function RabbitView(props) {
  let history = useNavigate();
  let params = useParams();

  const [rabbit, setRabbit] = useState([]);
  const [mother, setMother] = useState([]);
  const [father, setFather] = useState([]);
  const [ancestors, setAncestors] = useState([]);
  const [offsprings, setOffsprings] = useState([]);
  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let r = await SkuttliAPI.getRabbit(params.id);
      setRabbit(r);
      if(r.motherId){
        let motherObject = await SkuttliAPI.getRabbit(r.motherId);
        setMother(motherObject);
      }
      if(r.fatherId){
        let fatherObject = await SkuttliAPI.getRabbit(r.fatherId);
        setFather(fatherObject);
      }
      if(r.fatherId || r.motherId) {
        let ancestorsFromDb = await SkuttliAPI.getAncestorsForRabbit(r.rabbitId);
        setAncestors(ancestorsFromDb);
      }
      let offspringsFromDb = await SkuttliAPI.getOffspringsForRabbit(r.rabbitId);
      setOffsprings(offspringsFromDb);

    };
    load1();
  }, []);
  return (
    <Box p={2}>
      {/* <Button
        variant="contained"
        onClick={async () => {
          history("/ratCrud");
        }}
      >
        Lägg till
      </Button> */}
      <Card style={{ backgroundColor: "white", margin: "8px", padding: "16px" }}>

                  {rabbit.name}
                  <br />
                  {rabbit.color}
                  <br />
                  <i>{rabbit.id}</i>
                  <br />
                  <br />
                  {rabbit.fatherId &&(
                    <React.Fragment>
                    Far: <i>{father.name}{" "}{rabbit.fatherId}</i>
                    <br />
                    </React.Fragment>
                  )}
                  {rabbit.motherId &&(
                    <React.Fragment>
                    Mor: <i>{mother.name}{" "}{rabbit.motherId}</i>
                    <br />
                    </React.Fragment>
                  )}
                  <br />
                  <i>{rabbit.created}</i>
                  <br />
                  <br />
                  <b>Släktträd</b>
                  {ancestors.map((element) => {
                  return (
                    <Box>
                      {element.level} - {element.name}
                      </Box>
                  )})}
                  <br />
                  <b>Avkommor</b>
                  {offsprings.map((element) => {
                  return (
                    <Box>
                      {element.generation} - {element.name}
                      </Box>
                  )})}
                <br />
                <br />
                {/* <Button
                  variant="contained"
                  onClick={async () => {
                    await SkuttliAPI.deleteRabbit(rabbit.rabbitId);
                    history("/rabbits");
                    // let le = await SkuttliAPI.getRabbits();
                    // setRabbits(le);
                  }}
                >
                  Ta bort
                </Button> */}
                <br />
     
        
      </Card>
    </Box>
  );
}

export default RabbitView;
