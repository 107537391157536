import React, { useEffect } from "react";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import logo from "../logo.svg";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AppsIcon from "@mui/icons-material/Apps";
import HomeIcon from "@mui/icons-material/Home";
import Hidden from "@mui/material/Hidden";
import LoginView from "./LoginView";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ApplicationState from "../ApplicationState";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { BarChart } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RabbitsView from "./RabbitsView";
import RabbitCrudView from "./RabbitCrudView";
import RabbitView from "./RabbitView";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PetsIcon from '@mui/icons-material/Pets';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import ClubsView from "./ClubsView";

const MenuList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "red",
    backgroundColor: "#34242c !important",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: "linear-gradient(135deg, #282428 0%, #111 100%) ",
    color: "#fff",
    // '&, & .MuiListItemIcon-root': {
    //   color: 'pink',
    // },
  },
});

const drawerWidth = 240;

export default function TopBar(props) {
  const [, setHasLoggedIn] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Home");

  const { window2 } = props;
  const theme = useTheme();
  const [showMenuText, setShowMenuText] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const container =
    window2 !== undefined ? () => window().document.body : undefined;

  function doLogin() {
    setHasLoggedIn(true);
  }

  function logout() {
    ApplicationState.logout();
    window.location.href = "/";
    setHasLoggedIn(false);
    handleCloseUserMenu();
  }

  function handleCloseUserMenu() {
    setAnchorEl(null);
  }

  const drawer = (anchor) => (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      style={{ backgroundColor: "#111", color: "#fff" }}
    >
      <Grid item>
        {/* <Hidden smDown sx={{ display: { sm: 'block', xs: 'none' } }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ justifyContent: "center", padding: "24px" }}
        >
          <Box>
            <NavLink to="/timereport" onClick={() => {
                setMobileOpen(false);
                setSelectedMenuItem("Home");
              }}> 
            <img src={logo} style={{height: "50px",
                                width: "155px",
                                margin: "8px 8px 8px 4px",
                                top: "57px",
                                left: "58px",
                                alignContent: ""}} />
            </NavLink>
          </Box>
        </Grid>

        <Divider style={{borderColor: "#f6f6f6"}} />
        <Box p={5}></Box>
        <Divider style={{borderColor: "#f6f6f6"}} />
      </Hidden> */}
        <Box>
          <MenuList style={{ padding: "0px" }}>
            {ApplicationState.viewMenuItem() && (
              <Box>
                <ListItem
                  button
                  component={NavLink}
                  //to="/competitions"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Competitions");
                    window.location.href = "https://skuttli.com/CompetitionProgram.aspx";
                  }}
                  selected={selectedMenuItem === "Competitions"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <CalendarMonthIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Competitions" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  //to="/results"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Results");
                    window.location.href = "https://skuttli.com/CompetitionResults.aspx";
                  }}
                  selected={selectedMenuItem === "Results"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <EmojiEventsIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Results" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  to="/rabbits"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Rabbits");
                  }}
                  selected={selectedMenuItem === "Rabbits"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <FavoriteIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Rabbits" />}
                </ListItem>

                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  //to="/market"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Market");
                    window.location.href = "https://skuttli.com/LittersMarket.aspx";
                  }}
                  selected={selectedMenuItem === "Market"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <ShoppingBagIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Market" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  to="/clubs"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Clubs");
                    //window.location.href = "https://skuttli.com/Clubs.aspx";
                  }}
                  selected={selectedMenuItem === "Clubs"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <HomeIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Clubs" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  //to="/trainings"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Trainings");
                    window.location.href = "https://skuttli.com/Trainings.aspx";
                  }}
                  selected={selectedMenuItem === "Trainings"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <PetsIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Trainings" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />

                <ListItem
                  button
                  component={NavLink}
                  //to="/trainings"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Events");
                    window.location.href = "https://skuttli.com/Events.aspx";
                  }}
                  selected={selectedMenuItem === "Events"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <GroupsIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Events" />}
                </ListItem>
                
                <Divider style={{ borderColor: "#f6f6f6" }} />


              </Box>
            )}
            {ApplicationState.isAdmin() && (
              <Box>
                <ListItem
                  button
                  component={NavLink}
                  //to="/admin"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("Admin");
                    window.location.href = "https://skuttli.com/Admin/Admin.aspx";
                  }}
                  selected={selectedMenuItem === "Admin"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <SettingsIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="Admin" />}
                </ListItem>

                <Divider style={{ borderColor: "#f6f6f6" }} />
              </Box>
            )}
            {ApplicationState.isLoggedIn() && (
              <Box>
                <ListItem
                  button
                  component={NavLink}
                  //to="/mypage"
                  onClick={() => {
                    setMobileOpen(false);
                    setSelectedMenuItem("MyPage");
                    window.location.href = "https://skuttli.com/Users/MyPage.aspx";
                  }}
                  selected={selectedMenuItem === "MyPage"}
                  style={{ paddingBottom: "20px", paddingTop: "20px" }}
                >
                  <ListItemIcon style={{ minWidth: "42px" }}>
                    <PersonIcon style={{ color: "#fff" }} />
                  </ListItemIcon>
                  {showMenuText && <ListItemText primary="MyPage" />}
                </ListItem>
              </Box>
            )}
          </MenuList>
        </Box>
        <Divider style={{ borderColor: "#f6f6f6" }} />
        <Box p={5}></Box>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "16px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <AccountCircle
            style={{
              width: "85px",
              height: "85px",
              fontSize: "xx-large",
              color: "#d8d8d8",
            }}
          ></AccountCircle>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "12px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: "#fff",
              fontWeight: "600",
              padding: "8px",
              paddingTop: "0px",
              paddingBottom: "0px",
              wordBreak: "break-word",
              textAlign: "center",
            }}
          >
            {/* {ApplicationState.getState().user.firstName +
              " " +
              ApplicationState.getState().user.lastName} */}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "0px",
            // backgroundColor: "#f6f6f6",
          }}
        >
          <Link
            component="button"
            onClick={logout}
            style={{ color: "#fff", textDecoration: "underline" }}
            underline="hover"
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Log out</Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  if (1 != 1) {
    return (
      <>
        {/* <Router> */}
        <Routes>
          {/* <Switch> */}
          <Route
            path="/login"
            element={<LoginView doLoginFunction={doLogin} />}
          ></Route>
          <Route
            path="/"
            element={<LoginView doLoginFunction={doLogin} />}
          ></Route>
        </Routes>
        {/* </Switch> */}
        {/* </Router> */}
      </>
    );
  }

  return (
    <>
      <Router>
        <div style={{ display: "flex" }}>
          {["☰"].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
              <AppBar
                position="fixed"
                // className={classes.appBar}
                sx={{
                  backgroundColor: "#333",
                  color: "#000",
                  //width: {xs: "", sm: `calc(100% - ${drawerWidth}px)`},
                  // [theme.breakpoints.up("sm")]: {
                  //   width: `calc(100% - ${drawerWidth}px)`,
                  //   marginLeft: drawerWidth,
                  // },
                }}
              >
                <Toolbar>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <ListItem
                        button
                        onClick={handleDrawerToggle}
                        sx={{
                          paddingBottom: "20px",
                          paddingTop: "20px",
                        }}
                        size="large"
                        variant="outlined"
                      >
                        <MenuIcon style={{ color: "#fff" }} />
                      </ListItem>
                    </Grid>
                    <Grid item>
                      <NavLink
                        to="/rabbits"
                        onClick={() => {
                          setMobileOpen(false);
                          setSelectedMenuItem("Home");
                        }}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        {/* <img
                          src={logo}
                          style={{
                            height: "50px",
                            width: "155px",
                            margin: "8px 8px 8px 4px",
                            top: "57px",
                            left: "58px",
                            alignContent: "",
                          }}
                        /> */}
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {/* <Typography variant="h4">Skuttli 2.0 - BETA</Typography> */}
                          <img src="https://skuttli.com/resources/logo_transparent.png" style={{ height: "50px", width: "149px"}}></img>
                        </Grid>
                      </NavLink>
                    </Grid>

                    <Grid item>
                    <ListItem
                        button
                        onClick={handleDrawerToggle}
                        sx={{
                          paddingBottom: "20px",
                          paddingTop: "20px",
                        }}
                        size="large"
                        variant="outlined"
                      >
                        <MenuIcon style={{ color: "#fff" }} />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              {/* </Hidden> */}

              <Box
                // className={classes.drawer}
                sx={{
                  // width: {xs: 0, sm: drawerWidth},
                  width: { xs: 0 },
                  flexShrink: 0,
                  // [theme.breakpoints.up("sm")]: {
                  //   width: drawerWidth,
                  //   flexShrink: 0,
                  // },
                }}
                aria-label="mailbox folders"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  // classes={{
                  //   paper: classes.drawerPaper,
                  // }}
                  styles={{
                    width: drawerWidth,
                    backgroundColor: "#f6f6f6",
                    borderRightWidth: "0px",
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  style={{ borderRight: "0px solid white" }}
                  BackdropProps={{ invisible: true }}
                >
                  {drawer(anchor)}
                </Drawer>
              </Box>
              <Box sx={{ flexGrow: 1, paddingTop: { xs: "80px" } }}>
                {/* <Hidden smUp sx={{ display: { sm: 'none', xs: 'block' } }}> */}
                <div />
                {/* </Hidden> */}
                <Routes>
                  <Route path="/clubs" element={<ClubsView />}></Route>
                  <Route path="/rabbits" element={<RabbitsView />}></Route>
                  <Route path="/rabbitCrud" element={<RabbitCrudView />}></Route>
                  <Route path="/rabbit/:id" element={<RabbitView />}></Route>
                  {/* <Route path="/logs">
                  {ApplicationState.getState().user.email == "daniel.hagman@hellmandynamic.se" && (
                    <AdminLogView />
                  )}
                </Route> */}
                  <Route path="/" element={<RabbitsView />}></Route>
                </Routes>
              </Box>
            </React.Fragment>
          ))}
        </div>
      </Router>
    </>
  );
}
