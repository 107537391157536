import logo from "./logo.svg";
import "./App.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SkuttliAPI from "./SkuttliAPI";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import RabbitDto from "./Dtos/RabbitDto";
import RabbitsView from "./Components/RabbitsView";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import TopBar from "./Components/TopBar";

function App() {
  // const [rats, setRabbits] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    // const load1 = async () => {
    //   let le = await SkuttliAPI.getRabbits();
    //   setRabbits(le);
    // };
    // load1();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#EB71A9",
      },
      secondary: {
        main: "#fff",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <img src={logo} className="App-logo" alt="logo" />
        
        <Typography variant="h1">Skuttli</Typography>
        <RabbitsView></RabbitsView> */}
        <TopBar></TopBar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
